import '../css/app.scss';

/** Pages */

/** Global Components */

/** Bootstrap */

/** Components */
import Gallery from './components/gallery';
import ScrollAnimation from './components/scrollAnimation';
import LightboxGallery from './components/lightbox-gallery';

$(() => {
	// init global components
	
	// init commponents
	const $gallery = $('[data-gallery="el"]');

	if ($gallery.length) {
		$gallery.each((index, el) => {
			new Gallery($(el));
		});
	}

	const $lightboxGallery = $('[data-lightbox-gallery="el"]');

	if ($lightboxGallery.length) {
		$lightboxGallery.each((index, el) => {
			new LightboxGallery($(el));
		});
	}

});

// Wait for everything to be loaded
window.onload = () => {
	// Remove the loading class from the HTML element
	$('html').removeClass('loading');
	new ScrollAnimation();
};
