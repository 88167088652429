class Gallery {
  stateClasses = {
    showDetails: 'gallery__slide--show-details',
    buttonDisabled: 'gallery__button--disabled',
  };
  constructor($el) {
    this.$el = $el;
    this.$slider = $el.find('[data-gallery="slider"]');
    this.$slides = $el.find('[data-gallery="slide"]');
    this.$prev = $el.find('[data-gallery="prev"]');
    this.$next = $el.find('[data-gallery="next"]');
    this.$showMore = $el.find('[data-gallery="show-more"]');
    this.init();
  }

  init = () => {
    import("slick-carousel").then(() => {
      this.slick = this.$slider.slick({
        infinite: false,
        speed: 500,
        dots: false,
        autoplay: false,
        fade: false,
        arrows: false,
        variableWidth: true,
      });
    });

    this.$showMore.on('click', this.handleShowMoreClick);

    this.$prev.on('click', this.handlePrevClick);
    this.$next.on('click', this.handleNextClick);
  }

  handleShowMoreClick = (e) => {
    const $slide = $(e.currentTarget).closest('[data-gallery="slide"]');

    // toggle details
    $slide.toggleClass(this.stateClasses.showDetails);
  }

  handlePrevClick = () => {
    this.slick.slick('slickPrev');

    // check if the first slide is active
    if (this.slick.slick('slickCurrentSlide') === 0) {
      this.$prev.addClass(this.stateClasses.buttonDisabled);
    } else {
      this.$prev.removeClass(this.stateClasses.buttonDisabled);
    }

    this.$next.removeClass(this.stateClasses.buttonDisabled);
  }

  handleNextClick = () => {
    this.slick.slick('slickNext');

    // check if the last slide is active
    if (this.slick.slick('slickCurrentSlide') === this.$slides.length - 1) {
      this.$next.addClass(this.stateClasses.buttonDisabled);
    } else {
      this.$next.removeClass(this.stateClasses.buttonDisabled);
    }

    this.$prev.removeClass(this.stateClasses.buttonDisabled);
  }


}

export default Gallery;