import Lenis from '@studio-freight/lenis'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import Splitting from "splitting";

class ScrollAnimation {
  lenis;
  constructor() {
    this.$textAnimations = $('[data-text-animation]');
    this.$parallax = $('[data-parallax]');
    this.init();
  }

  init = () => {
    // init splitting
    Splitting();

    // init smooth scrolling
    this.initSmoothScrolling();

    // init scroll triggers
    this.initScrollTriggers();
  }

  initSmoothScrolling = () => {
    this.lenis = new Lenis({
      lerp: 0.2,
      smooth: true
    });
  
    this.lenis.on('scroll', () => ScrollTrigger.update());
  
    const scrollFn = (time) => {
      this.lenis.raf(time);
      requestAnimationFrame(scrollFn);
    };

    window._lenis = this.lenis;
    
    requestAnimationFrame(scrollFn);
  }

  initScrollTriggers = () => {
    // loop through all text animations
    this.$textAnimations.each((index, tile) => {
      const chars = tile.querySelectorAll('.char');

      // check if tile has data attribute scrub
      const scrub = Boolean(tile.dataset.scrub);

      gsap.fromTo(chars, { 
          'will-change': 'opacity, transform', 
          opacity: 0, 
          yPercent: 10, 
          scaleY: 1.5, 
          scaleX: 0.7, 
          transformOrigin: '50% 0%' 
      }, 
      {
          duration: 1,
          ease: 'back.inOut(2)',
          opacity: 1,
          yPercent: 0,
          scaleY: 1,
          scaleX: 1,
          stagger: 0.03,
          scrollTrigger: {
              trigger: tile,
              start: 'center bottom+=50%',
              end: 'bottom top+=40%',
              scrub: scrub,
              markers: false,
          }
      });
    });

    this.$parallax.each((index, tile) => {
      
      const picture = tile.querySelectorAll('[data-picture]');

      // check if picture has data attribute scrub
      const scrub = Boolean(tile.dataset.scrub);

      gsap.fromTo(picture, { 
          'will-change': 'transform', 
          yPercent: 20, 
          transformOrigin: '50% 0%' 
      }, 
      {
          ease: 'ease.inOut(2)',
          yPercent: 0,
          stagger: 0.1,
          scrollTrigger: {
              trigger: tile,
              // start: 'center bottom+=50%',
              // end: 'bottom top+=40%',
              start: 'top bottom',
              end: 'top top+=10%',
              scrub: scrub,
              markers: false,
          }
      });
    });
  }
}

export default ScrollAnimation;