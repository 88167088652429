import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

class LightboxGallery {
  icons = {
    leftArrowSVGString: `<svg aria-hidden="true" class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewbox="0 0 60 60"><path fill="none" fill-rule="evenodd" stroke="#000" d="M34 42 22 30l12-12"/></svg>`,
    lupe: `<svg aria-hidden="true" class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"><g fill="none" fill-rule="evenodd" stroke="#000" transform="rotate(45 2.343 22.364)"><circle cx="7.5" cy="7.5" r="7.5"/><path stroke-linecap="square" d="M8.207 15.307v5.6"/></g></svg>`,
    close: `<svg aria-hidden="true" class="pswp__icn xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32"><g fill="none" fill-rule="evenodd" stroke="#000"><path d="m6 6 10 10L6 26M26 26 16 16 26 6"/></g></svg>`
  }

  constructor($el) {
    this.$el = $el;
    this.$children = this.$el.find('[data-lightbox-gallery="item"]');
    console.log('Lightbox initialized');
    this.init();
  }

  init = () => {
    this.lightbox = new PhotoSwipeLightbox({
      arrowPrevSVG: this.icons.leftArrowSVGString,
      arrowNextSVG: this.icons.leftArrowSVGString,
      closeSVG: this.icons.close,
      zoomSVG: this.icons.lupe,

      gallery:  this.$el[0],
      children: this.$children.toArray(),
      pswpModule: PhotoSwipe,
      bgOpacity: 1,

    });

    this.lightbox.on('beforeOpen', () => {
      console.log('Lightbox opened');
      window._lenis.stop();
    });

    this.lightbox.on('close', () => {
      console.log('Lightbox closed');
      window._lenis.start();
    });  

    this.lightbox.init();
  }
}

export default LightboxGallery;